import React from "react";
import styled from 'styled-components';
// import { Link } from "gatsby";
import {MAX_MAIN_WIDTH, PAGE_PADDING, responsive} from "../styles/dimens";
import Helmet from "react-helmet";
import {EMAIL_ADDRESS} from "../constants";

const Container = React.memo(styled.main`
  padding: ${PAGE_PADDING};
  margin: auto;
  max-width: ${MAX_MAIN_WIDTH};
  h1 {
    text-align: center;
    ${responsive({
      'phablet-desktop': 'margin-top: 10px;',
      mobile: 'margin-top: 5px'
    })};
  }
  .faq {
    margin-top: 25px;
    .question {
      font-weight: bold
    }
    .answer {
      margin-bottom: 25px;
    }
  }
`);

const AboutPage = React.memo(() => (
  <Container>
    <Helmet>
      <title>Recipe Watch - About</title>
      <meta property="og:title" content='Recipe Watch - About' />
      <meta property="og:type" content="website" />
    </Helmet>
    <h1>About Recipe Watch</h1>
    <div className='faq'>
      <h2>FAQ</h2>
      <p className='question'>
        How do you compare recipes of different serving sizes / numbers of servings?
      </p>
      <p className='answer'>
        For comparisons, recipes are compared calorie-for-calorie, the theory
        being that the type of calories is what matters most.
        You'd rather 200 calories filled with vitamins and important
        macronutrients than "empty calories." Similarly if you're trying to
        avoid certain things like fat or carbs then obviously you'd rather get
        your calories from other sources than fat or carbs.
      </p>
      <p className='question'>
        Why does there sometimes appear to be outsized effect from fat on the
        nutrition of a recipe?
      </p>
      <p className='answer'>
        Fat is more energy dense than protein and carbohydrates,
        more than twice as dense.
        Fat is approximately 9 calories per gram,
        while protein and carbs are each approximately 4 cal/gram.
        So basically when using an equal weight of fatty ingredients,
        these contribute more to the overall calories of the recipe.
      </p>
      <h2>Contact</h2>
      <p>
        For any questions or comments, contact us at
        {' '} <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
      </p>
    </div>
  </Container>
));

export default AboutPage;
